// Style für den Header
#header {
  
  .small-logo {
    width: 50px;
  }
  
  select#languageSelection {
    // Weißer Pfeil für Sprachauswahl
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    border: none;
  }
  
}
