// Hier darf '/bootstrap/scss/variables' **nicht** importiert werden

$fa-font-path: '../../../node_modules/font-awesome/fonts';

// Basisschriftfamilie
$font-family-sans-serif: "Ubuntu Regular", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
"Noto Color Emoji" !default;

// Farben
$primary: #36abe0;
$dark: #003b6f;

// Abstand zwischen den Überschriften
$headings-line-height: 1.6;

// Allgemeine Schriftgröße
$font-size-base: 1rem; // Standard: 1rem;

// Größe der Überschriften um den Faktor 0.1 kleiner gemacht
$h1-font-size: $font-size-base * 2.4 !default;
$h2-font-size: $font-size-base * 1.9 !default;
$h3-font-size: $font-size-base * 1.65 !default;
$h4-font-size: $font-size-base * 1.4 !default;
$h5-font-size: $font-size-base * 1.15 !default;
$h6-font-size: $font-size-base !default;
