@font-face {
  font-family: "Ubuntu Regular";
  font-weight: normal;
  font-style: normal;
  src: local(""),
  url("../static/fonts/ubuntu-regular.woff2") format('woff2'),
  url("../static/fonts/ubuntu-regular.woff") format('woff'),
  url("../static/fonts/ubuntu-regular.ttf") format('truetype'),
  url("../static/fonts/ubuntu-regular.svg#Ubuntu Regular") format('svg');
}


@import "../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
//@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
//@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
//@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
//@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
//@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
//@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
//@import "../node_modules/bootstrap/scss/spinners";
//@import "../node_modules/bootstrap/scss/offcanvas";
//@import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Bootstrap-Icons
$bootstrap-icons-font-src: url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2?a74547b2f0863226942ff8ded57db345") format("woff2"),
url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff?a74547b2f0863226942ff8ded57db345") format("woff") !default;
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

// Highlight.js für die Darstellung von Code-Abschnitten
//@import "../node_modules/highlight.js/scss/default.scss";

@import "header";
@import "footer";
@import "breadcrumbs";

.btn-warning {
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.6);
}

.btn-primary {
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.6);
}

h2 {
  margin-top: 1rem;
}

ul {
  padding-left: 1.5rem;
}

// Lightgallery
.lightgallery img {
  max-width: 100%;
}
